<template>
  <div class="container">
    <div class="home_one-featured-wrapper bg_secondary round-10">
      <div class="row justify-content-center gx-xxl-6">
        <div class="col-lg-4 col-md-6" v-for="feature in features" :key="feature.id" data-aos="fade-up"
          data-aos-duration="1000" :data-aos-delay="feature.dataDelay">
          <div class="featured-card">
            <span class="feature-icon d-flex flex-column justify-content-center align-items-center">
              <img :src="feature.imageIcon" alt="Image" />
            </span>
            <h3 class="fs-20 text-white">
              {{ feature.title }}
            </h3>
            <p class="text-offwhite mb-0">
              {{ feature.desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FeaturesContent",
  data() {
    return {
      features: [
        {
          id: 1,
          dataDelay: "100",
          imageIcon: require("../../assets/img/icons/mention.svg"),
          title: "Facilidade",
          desc: "Com os Planos da Médico aqui, você conta com um atendimento de excelência. Evite custos elevados e processos burocráticos.",
        },
        {
          id: 2,
          dataDelay: "200",
          imageIcon: require("../../assets/img/icons/attachment.svg"),
          title: "Segurança",
          desc: "Adquirir um dos planos da Médico Aqui é investir em segurança, tranquilidade e conforto sem sair de casa.",
        },
        {
          id: 3,
          dataDelay: "300",
          imageIcon: require("../../assets/img/icons/cube.svg"),
          title: "Mais tempo para você aproveitar o dia",
          desc: "Sem pressão de tempo, esqueça a espera em filas e multidões.",
        },
        {
          id: 4,
          dataDelay: "400",
          imageIcon: require("../../assets/img/icons/document.svg"),
          title: "Tenha tudo na palma da sua mão",
          desc: "Sem deslocamentos indesejados e 100% digital.",
        },
        {
          id: 5,
          dataDelay: "500",
          imageIcon: require("../../assets/img/icons/files.svg"),
          title: "100% online",
          desc: "Atestado, receituário e solicitação de exames 100% online com segurança e autenticidade.",
        },
        {
          id: 6,
          dataDelay: "600",
          imageIcon: require("../../assets/img/icons/mention.svg"),
          title: "Clube de benefícios",
          desc: "Tenha descontos em farmácias e estabelecimentos parceiros.",
        },
      ],
    };
  },
});
</script>

<style lang="scss" scoped>
.home_one-featured-wrapper {
  padding: 110px 50px 60px;

  .featured-card {
    margin-bottom: 52px;
    transition: var(--transition);

    .feature-icon {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      border: 1px solid rgba(167, 97, 248, 0.1);
      margin-bottom: 20px;
    }

    h3 {
      margin-bottom: 11px;
      font-weight: 400;
    }

    p {
      padding-right: 35px;
    }

    &:hover {
      transform: translateY(-4px);
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-featured-wrapper {
    padding: 50px 20px 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_one-featured-wrapper {
    padding: 110px 30px 60px;

    .featured-card {
      p {
        padding-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_one-featured-wrapper {
    padding: 110px 110px 50px;

    .featured-card {
      p {
        width: calc(100% + 15px);
        padding-right: 0;
      }
    }
  }
}
</style>