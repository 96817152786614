<template>
  <div id="price-area" class="container pb-70 mn-section">
    <div class="row align-items-center mb-50" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
      <div class="col-lg-6 col-md-7 pe-xxl-6">
        <h2 class="section-title mb-0 pe-xxl-5">
          Conheça nossos planos
        </h2>
      </div>
      <div class="col-lg-6 col-md-5 text-md-end mt-sm-20">
        <router-link to="/contato" class="btn style-one">
          Dúvidas?
        </router-link>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
        <div class="home_one-pricing-card bg-magnolia round-10 mb-30 position-relative transition">
          <h6 class="fs-20 text-title">Bronze</h6>
          <h2>
            R$ -
            <span class="fs-15 fw-normal f-primary position-relative text-para">
              /mensal
            </span>
          </h2>
          <p class="mb-0 br-two">
            Plano mensal Bronze
          </p>
          <ul class="pricing-features list-style">
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              1 pessoa por plano
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Atestado digital
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Receitas digitais
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />No
              Encaminhamento
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Orientação médica
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Clínico geral
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Pediatria
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Medicamentos com desconto
            </li>
          </ul>
          <router-link to="#" class="btn style-three fw-medium">
            Eu quero
          </router-link>
        </div>
      </div>
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
        <div class="home_one-pricing-card active featured bg-magnolia round-10 mb-30 position-relative transition">
          <span class="featured-tag fs-15 text-white position-absolute end-0 d-inline-block lh-1">
            Popular
          </span>
          <h6 class="fs-20 text-title">
            Prata

          </h6>
          <h2>
            R$ -
            <span class="fs-15 fw-normal f-primary position-relative text-para">
              /mensal
            </span>
          </h2>
          <p class="mb-0 br-two">
            Plano mensal Prata
          </p>
          <ul class="pricing-features list-style">
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              1 pessoa por plano
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Atestado digital
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Receitas digitais
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />No
              Encaminhamento
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Orientação médica
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Clínico geral
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Pediatria
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Medicamentos com desconto
            </li>
          </ul>
          <router-link to="#" class="btn style-three fw-medium">
            Eu quero
          </router-link>
        </div>
      </div>
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
        <div class="home_one-pricing-card bg-magnolia round-10 mb-30 position-relative transition">
          <h6 class="fs-20 text-title">Premium</h6>
          <h2>
            R$ -
            <span class="fs-15 fw-normal f-primary position-relative text-para">
              /mensal
            </span>
          </h2>
          <p class="mb-0 br-two">
            Plano mensal Ouro </p>
          <ul class="pricing-features list-style">
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              1 pessoa por plano
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Atestado digital
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Receitas digitais
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />No
              Encaminhamento
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Orientação médica
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Clínico geral
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Pediatria
            </li>
            <li class="checked text-title fw-medium">
              <img src="../../assets/img/icons/check.svg" alt="Image" />
              Medicamentos com desconto
            </li>
          </ul>
          <router-link to="#" class="btn style-three fw-medium">
            Eu quero
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingPlan",
};
</script>

<style lang="scss" scoped>
.home_one-pricing-card {

  &.bg-magnolia {
    background-color: #effeff;
  }

  padding: 45px 25px 50px;
  z-index: 1;
  overflow: hidden;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 0%;
    height: 100%;
    background-image: url(../../assets/img/doctor-here/bg-1.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    transition: var(--transition);
  }

  h6 {
    font-weight: 500;
    line-height: 28px;
    margin: 0;

    span {
      background: var(--whiteColor);
      padding: 5.5px 11.5px;
      top: -4px;
      margin-left: 8px;
      font-size: 12px;
      letter-spacing: -0.3px;
      color: var(--optionalColor);
    }
  }

  h2 {
    color: var(--bruntColor);
    font-size: 54px;
    font-weight: 700;
    line-height: 52px;
    margin: 32px 0 3px;

    span {
      margin-left: 2px;
      top: -1px;
    }
  }

  p {
    padding-bottom: 26px;
  }

  .featured-tag {
    background-image: url(../../assets/img/doctor-here/feature-tag-bg.png);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    padding: 5.5px 22px 7.5px 28px;
    font-weight: 300;
    top: 55px;
  }

  .pricing-features {
    padding: 32px 0 40px;

    li {
      position: relative;
      margin-bottom: 24.5px;
      font-weight: 500;
      padding-left: 28px;
      letter-spacing: 0.012px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        top: 4.5px;
        left: 0;
      }
    }
  }

  &.active,
  &:hover {
    .btn {
      &:before {
        width: 100%;
        background: linear-gradient(92deg, #45bdc4 0%, #1e6f75 100%);
        z-index: -1;
      }

      border-color: transparent;
      color: var(--whiteColor);
    }

    &:after {
      width: 100%;
      visibility: visible;
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-pricing-card {
    padding: 30px 22px 35px;

    h2 {
      font-size: 48px;
      line-height: 55px;
    }

    p {
      padding-bottom: 26px;
    }

    .featured-tag {
      top: 35px;
    }

    .pricing-features {
      padding: 25px 0 34px;

      li {
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 18px;

        img {
          top: 2.5px;
          max-width: 15px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_one-pricing-card {
    padding: 45px 50px 50px;
  }
}
</style>