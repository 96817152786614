import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import ErrorPage from "../pages/ErrorPage.vue";
import HomeOnePage from "../pages/HomeOnePage.vue";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage.vue";
import TermsConditionsPage from "../pages/TermsConditionsPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeOnePage",
    component: HomeOnePage,
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;