import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6395436a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "home_one-featured-wrapper bg_secondary round-10" }
const _hoisted_3 = { class: "row justify-content-center gx-xxl-6" }
const _hoisted_4 = ["data-aos-delay"]
const _hoisted_5 = { class: "featured-card" }
const _hoisted_6 = { class: "feature-icon d-flex flex-column justify-content-center align-items-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "fs-20 text-white" }
const _hoisted_9 = { class: "text-offwhite mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-lg-4 col-md-6",
            key: feature.id,
            "data-aos": "fade-up",
            "data-aos-duration": "1000",
            "data-aos-delay": feature.dataDelay
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createElementVNode("img", {
                  src: feature.imageIcon,
                  alt: "Image"
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("h3", _hoisted_8, _toDisplayString(feature.title), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(feature.desc), 1)
            ])
          ], 8, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}