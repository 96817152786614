<template>
  <div id="hw-area" class="container home_one-hw-wrapper ptb-100 mn-section">
    <div class="row">
      <div class="col-xl-6 offset-xl-3 col-md-8 offset-md-2" data-cue="slideInUp">
        <h2 class="section-title text-center mb-40">
          Conheça nosso aplicativo
        </h2>
      </div>
    </div>
    <div class="hw-card style-one d-flex align-items-center round-10" data-cue="slideInUp">
      <img src="../../assets/img/home-one/right-arrow.webp" alt="Image" class="right-arrow sm-none" />
      <div class="hw-img round-10">
        <img src="../../assets/img/doctor-here/stores.png" alt="Image" class="round-10" />
      </div>
      <div class="hw-info">
        <div class="d-flex justify-content-between align-items-center">
          <div class="hw-icon d-flex flex-column justify-content-center align-items-center">
            <img src="../../assets/img/icons/user-circle.svg" alt="Image" />
          </div>
          <span class="hw-counter fs-26 fw-medium f-secondary text_secondary">
            01.
          </span>
        </div>
        <h5>Baixe e instale nosso aplicativo</h5>
        <p>
          Baixe agora nosso aplicativo e tenha acesso rápido e fácil a todas as funcionalidades mencionadas. Cuide da
          sua saúde com praticidade e conveniência, onde quer que esteja.
          Acesse no site e clique no botão correspondente a sua plataforma (Android ou iOS).
        </p>
      </div>
    </div>
    <div class="hw-card style-two d-flex align-items-center round-10" data-cue="slideInUp">
      <img src="../../assets/img/home-one/left-arrow.webp" alt="Image" class="left-arrow sm-none" />
      <div class="hw-info">
        <div class="d-flex justify-content-between align-items-center">
          <div class="hw-icon d-flex flex-column justify-content-center align-items-center">
            <img src="../../assets/img/icons/down-circle.svg" alt="Image" />
          </div>
          <span class="hw-counter fs-26 fw-medium f-secondary text_secondary">
            02.
          </span>
        </div>
        <h5>Crie sua conta</h5>
        <p>
          Crie sua conta agora e acesse todas as facilidades oferecidas pelo nosso aplicativo. Entre para desfrutar de
          uma experiência personalizada e completa em cuidados de saúde, tudo ao seu alcance.
        </p>
      </div>
      <div class="hw-img round-10">
        <img src="../../assets/img/doctor-here/download-app.png" alt="Image" class="round-10" />
      </div>
    </div>
    <div class="hw-card style-one d-flex align-items-center round-10" data-cue="slideInUp">
      <img src="../../assets/img/home-one/shape-1.webp" alt="Image"
        class="right-shape position-absolute bounce lg-none" />
      <div class="hw-img round-10">
        <img src="../../assets/img/home-one/how-it-works/hw-img-3.webp" alt="Image" class="round-10" />
      </div>
      <div class="hw-info">
        <div class="d-flex justify-content-between align-items-center">
          <div class="hw-icon d-flex flex-column justify-content-center align-items-center">
            <img src="../../assets/img/icons/diamond.svg" alt="Image" />
          </div>
          <span class="hw-counter fs-26 fw-medium f-secondary text_secondary">
            03.
          </span>
        </div>
        <h5>Agora você está pronto e conectado!</h5>
        <p>
          Sem fila e sem espera: com a Médico Aqui cuidar da saúde é fácil. Desfrute de todas as funcionalidades do
          nosso aplicativo!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
};
</script>

<style lang="scss" scoped>
.home_one-hw-wrapper {
  .hw-card {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }

    .hw-img {
      width: 418px;
    }

    .hw-info {
      width: calc(100% - 488px);

      .hw-icon {
        background-color: #057a88;

        img {
          position: relative;
          left: 1px;
          top: 1px;
        }
      }
    }

    &.style-one {
      margin-right: 190px;
      position: relative;
      background-color: #f2feff;
      padding: 30px;
      border-radius: 10px;

      .right-arrow {
        position: absolute;
        bottom: 0;
        right: -125px;
      }

      .hw-info {
        margin-left: 70px;
      }

      .right-shape {
        top: 46%;
        transform: translateY(-50%);
        right: -200px;
      }
    }

    &.style-two {
      margin-left: 190px;
      position: relative;
      background-color: #f9f4ff;
      padding: 30px;
      border-radius: 10px;
      background-image: url(../../assets/img/doctor-here/bg-1.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .left-arrow {
        position: absolute;
        bottom: 0;
        left: -125px;
      }

      .hw-info {
        margin-right: 70px;

        .hw-icon {
          background-color: var(--bruntColor);
        }
      }
    }

    .hw-info {
      .hw-icon {
        align-items: center;
        width: 72px;
        height: 72px;
        border-radius: 50%;

        img {
          display: block;
          margin: 0 auto;
        }
      }

      h5 {
        font-size: 26px;
        line-height: 37px;
        margin: 16px 0 12px;
      }

      p {
        margin: 0;
      }

      .hw-counter {
        padding-right: 30px;
        font-weight: 600;
        margin-top: 8px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-hw-wrapper {
    .hw-card {
      flex-wrap: wrap;

      .hw-img {
        width: 100%;
      }

      .hw-info {
        width: 100%;

        .hw-icon {
          width: 55px;
          height: 55px;

          img {
            max-width: 30px;
            left: 0;
          }
        }

        .hw-counter {
          padding-right: 0;
        }
      }

      &.style-one {
        padding: 20px;
        margin-right: 0;

        .hw-img {
          margin-bottom: 25px;
        }

        .right-shape {
          right: 0px;
        }

        .hw-info {
          margin-left: 0;
        }
      }

      &.style-two {
        padding: 20px;
        margin-left: 0;

        .hw-info {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_one-hw-wrapper {
    .hw-card {
      .hw-img {
        width: 250px;
      }

      .hw-info {
        width: calc(100% - 270px);
      }

      &.style-one {
        margin-right: 50px;

        .right-arrow {
          right: -45px;
          max-width: 80px;
        }

        .right-shape {
          right: 0px;
        }

        .hw-info {
          margin-left: 20px;
        }
      }

      &.style-two {
        margin-left: 50px;

        .left-arrow {
          left: -45px;
          max-width: 80px;
        }

        .hw-info {
          margin-right: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_one-hw-wrapper {
    .hw-card {
      .hw-img {
        width: 380px;
      }

      .hw-info {
        width: calc(100% - 420px);
      }

      &.style-one {
        margin-right: 100px;

        .right-arrow {
          position: absolute;
          bottom: 0;
          right: -85px;
        }

        .hw-info {
          margin-left: 40px;
        }
      }

      &.style-two {
        margin-left: 100px;

        .left-arrow {
          position: absolute;
          bottom: 0;
          left: -85px;
        }

        .hw-info {
          margin-right: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_one-hw-wrapper {
    .hw-card {
      &.style-one {
        margin-right: 210px;

        .hw-info {
          padding-right: 40px;

          .hw-counter {
            padding-right: 0;
          }
        }
      }

      &.style-two {
        margin-left: 210px;

        .hw-info {
          padding-left: 40px;

          .hw-counter {
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .home_one-hw-wrapper {
    .hw-card {
      &.style-one {
        .right-shape {
          right: -250px;
        }
      }
    }
  }
}
</style>