<template>
  <div class="container">
    <swiper :loop="false" :speed="15000" :spaceBetween="25" :simulateTouch="false" :autoplay="{
      delay: 1,
      disableOnInteraction: true,
    }" :breakpoints="{
      0: {
        slidesPerView: 2,
      },
      576: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      },
    }" :modules="modules" class="brand-slider mb-50">
      <swiper-slide v-for="logo in logos" :key="logo.id">
        <div class="brand-logo">
          <img :src="logo.image" alt="Image" class="d-block mx-auto" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  name: "BrandLogo",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      logos: [
        {
          id: 1,
          image: require("../../assets/img/doctor-here/brand/aws.png"),
        },
        {
          id: 2,
          image: require("../../assets/img/doctor-here/brand/microsoft.png"),
        },
        {
          id: 3,
          image: require("../../assets/img/doctor-here/brand/meta.png"),
        },
        {
          id: 4,
          image: require("../../assets/img/doctor-here/brand/instant.png"),
        },
        {
          id: 5,
          image: require("../../assets/img/doctor-here/brand/google-play.png"),
        },
        {
          id: 6,
          image: require("../../assets/img/doctor-here/brand/apple-store.png"),
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
};
</script>