<template>
  <header id="navbar-wrap" :class="['navbar-area', { sticky: isSticky }]">
    <div class="container">
      <div class="navbar-wrapper d-flex align-items-center">
        <router-link to="/" class="logo">
          <img class="logo logo-light" src="../../assets/img/doctor-here/logo-1.png" alt="Image" />

          <img class="logo logo-dark" src="../../assets/img/doctor-here/logo-white.png" alt="Image" />
        </router-link>

        <div class="other-options d-flex align-items-center ms-auto d-lg-none">
          <div class="app-btn d-flex align-items-center justify-content-end">
            <a :href="appStoreUrl" class="d-flex flex-column justify-content-center align-items-center rounded-circle">
              <img src="../../assets/img/icons/apple-store.svg" alt="Image" />
            </a>
            <a :href="playStoreUrl" class="d-flex flex-column justify-content-center align-items-center rounded-circle">
              <img src="../../assets/img/icons/google-play.svg" alt="Image" />
            </a>
          </div>

          <a class="navbar-toggler d-lg-none" data-bs-toggle="offcanvas" href="#navbarOffcanvas" role="button"
            aria-controls="navbarOffcanvas">
            <span class="burger-menu">
              <span class="top-bar"></span>
              <span class="middle-bar"></span>
              <span class="bottom-bar"></span>
            </span>
          </a>
        </div>

        <nav id="navbar" class="navbar">
          <div class="navbar-collapse d-flex justify-content-center">
            <ul class="navbar-nav md-none">
              <li class="nav-item">
                <a class="nav-link" href="#inicio">Início</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#quem-somos">Quem somos</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#como-baixar">Como baixar</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#ppreco">Preço</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contato">Fale conosco</a>
              </li>

            </ul>
          </div>
        </nav>

        <div class="app-btn d-flex align-items-center justify-content-end ms-auto md-none">
          <a href="https://www.apple.com/store" target="_blank"
            class="d-flex flex-column justify-content-center align-items-center rounded-circle">
            <img src="../../assets/img/icons/apple-store.svg" alt="Image" />
          </a>
          <a href="https://play.google.com/store/games?hl=en&gl=US&pli=1" target="_blank"
            class="d-flex flex-column justify-content-center align-items-center rounded-circle">
            <img src="../../assets/img/icons/google-play.svg" alt="Image" />
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { APPSTORE_URL, PLAYSTORE_URL } from '@/configs/global';
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "MainNavbar",
  setup() {
    const isSticky = ref(false);

    onMounted(() => {
      window.addEventListener("scroll", () => {
        let scrollPos = window.scrollY;
        isSticky.value = scrollPos >= 100;
      });
    });

    return {
      isSticky,
      appStoreUrl: APPSTORE_URL,
      playStoreUrl: PLAYSTORE_URL
    };
  },
});
</script>

<style lang="scss" scoped>
.navbar-area {
  transition: var(--transition);
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10;

  &.home_one,
  &.home_three,
  &.home_four,
  &.home_five,
  &.home_six,
  &.home_seven {
    top: 35px;

    .navbar-wrapper {
      background-color: var(--whiteColor);
      padding: 5px 21px;
      border-radius: 50px;
    }
  }

  &.home_two {
    padding: 19px 0;
    border-bottom: 1px solid rgba(37, 27, 66, 0.13);

    &.sticky {
      padding: 0;
      position: fixed;
    }
  }

  &.home_four {
    .navbar-wrapper {
      border: 1px solid var(--secondaryColor);
    }

    &.sticky {
      .navbar-wrapper {
        border-color: transparent;
      }
    }
  }

  &.home_six {
    .navbar-wrapper {
      border: 1px solid rgba(37, 27, 66, 0.32);
    }

    &.sticky {
      .navbar-wrapper {
        border-color: transparent;
      }
    }
  }

  &.home_eight {
    padding-top: 17px;

    &.sticky {
      position: fixed;
    }

    .navbar {
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: #4f456b;

            &.active,
            &:hover {
              color: var(--optionalColor);
            }
          }
        }
      }
    }

    .other-options {
      .search-btn {
        margin-right: 20px;

        i {
          font-size: 22px;
        }
      }

      .login-btn {
        font-weight: 500;
        padding: 10px 34px;
        border-radius: 50px;
        transition: var(--transition);

        &:first-child {
          color: var(--menuColor);
          border: 1px solid var(--menuColor);
          margin-right: 20px;

          &:hover {
            background-color: var(--bruntColor);
            color: var(--whiteColor);
            border-color: transparent;
          }
        }

        &:last-child {
          background-color: var(--bruntColor);
          color: var(--whiteColor);

          &:hover {
            background-color: var(--titleColor);
          }
        }
      }
    }
  }

  .navbar-toggler {
    box-shadow: unset;
    border: none;
    padding: 0;
    display: block;

    .burger-menu {
      top: auto;
      cursor: pointer;

      span {
        top: auto;
        height: 3px;
        width: 28px;
        margin: 0 0 5.5px;
        display: block;
        border-radius: 10px;
        background: var(--primaryColor);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.sticky {
    position: sticky;
    top: 0 !important;
    left: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0 0 15px rgba(37, 27, 66, 0.13);
    background-color: var(--whiteColor);
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

    .navbar-wrapper {
      padding: 13px 0;
    }

    &.home_eight {
      padding-top: 0;

      .navbar-wrapper {
        padding: 13px 0;
      }
    }
  }

  .navbar {
    .navbar-nav {
      display: block;
      padding-left: 40px;

      .nav-item {
        display: inline-block;
        margin-right: 44px;

        &:last-child {
          margin-right: 0;
        }

        .nav_link,
        .nav-link {
          color: var(--menuColor);
          font-weight: 500;
          font-size: 15px;
          display: inline-block;
          position: relative;
          padding-left: 11px;

          &:after {
            position: absolute;
            top: 19px;
            left: 0;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: rgba(68, 188, 196, 0.47);
            transition: var(--transition);
          }

          &:hover,
          &.active {
            color: var(--primaryColor);

            &:after {
              background-color: var(--primaryColor);
            }
          }
        }

        .nav_link {
          &:after {
            top: 12px;
          }
        }

        &.has-child {
          position: relative;

          a {
            font-size: 15px;
            font-weight: 500;
            color: var(--menuColor);

            &:hover,
            &.active {
              color: var(--primaryColor);
            }
          }

          &:hover {
            .dropdown-menu {
              opacity: 1;
              visibility: visible;
              transform: translate3d(0, 0, 0);
            }
          }
        }

        .dropdown-menu {
          left: 0;
          top: 30px;
          opacity: 0;
          z-index: 99;
          width: 265px;
          margin-top: 0;
          display: block;
          padding: 10px 25px;
          border: none;
          border-radius: 0;
          position: absolute;
          visibility: hidden;
          transition: all 0.2s ease-in-out;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
          transform: translate3d(0, 18px, 0);

          .nav-item {
            margin: 0;
            display: block;

            a {
              display: block;
              font-size: 15px;
              padding: 8px 0;
              color: var(--menuColor);
              opacity: 0.8;
              position: relative;
              border-bottom: 1px solid rgba(167, 97, 248, 0.16);
              transition: var(--transition);

              &.dropdown-toggle {
                &:after {
                  margin: 0;
                  border: none;
                }

                &:before {
                  position: absolute;
                  top: 10px;
                  right: 0;
                  content: "\ea6e";
                  font-family: remixicon !important;
                  font-size: 17px;
                  color: var(--menuColor);
                }

                &.active,
                &:hover {
                  &:before {
                    color: var(--primaryColor);
                  }
                }
              }

              &:after {
                position: absolute;
                top: 50%;
                left: -25px;
                content: "";
                width: 3px;
                height: 0;
                background-color: var(--primaryColor);
                transform: translateY(-50%);
                visibility: hidden;
                opacity: 0;
                transition: var(--transition);
              }

              &.active,
              &:hover {
                color: var(--primaryColor);
                opacity: 1;

                &:after {
                  visibility: visible;
                  opacity: 1;
                  height: 25px;
                }
              }
            }

            &:last-child {
              a {
                border-bottom: none;
              }
            }

            .dropdown-menu {
              top: 0;
              opacity: 0;
              left: -290px;
              margin-top: 0;
              visibility: hidden;
              transform: translate3d(0, 18px, 0);

              .nav-item {
                .nav-link {
                  color: var(--whiteColor);

                  &.active,
                  &:hover {
                    color: var(--primaryColor);
                  }

                  &:hover {
                    .dropdown-menu {
                      opacity: 1;
                      visibility: visible;
                      transform: translate3d(0, 0, 0);
                    }
                  }
                }

                .dropdown-menu {
                  top: 0;
                  opacity: 0;
                  left: -100%;
                  visibility: hidden;
                  transform: translate3d(0, 18px, 0);

                  .nav-item {
                    &:hover {
                      .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: translate3d(0, 0, 0);
                      }
                    }

                    .dropdown-menu {
                      top: 0;
                      opacity: 0;
                      left: 100%;
                      visibility: hidden;
                      transform: translate3d(0, 18px, 0);

                      .nav-item {
                        .nav-link {
                          color: var(--whiteColor);

                          &:hover,
                          &.active {
                            color: var(--primaryColor);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            &:hover {
              .dropdown-menu {
                opacity: 1;
                visibility: visible;
                transform: translate3d(0, 0, 0);
              }
            }
          }

          >.nav-item {
            &:last-child {
              >.nav-link {
                border-bottom: none;
              }
            }
          }
        }
      }

      &>.nav-item {
        &>.dropdown-toggle {
          position: relative;
          padding-right: 18px;

          &:after {
            display: none;
          }

          &:before {
            position: absolute;
            top: -3px;
            right: 0;
            content: "\ea4e";
            font-family: remixicon !important;
            font-size: 16px;
            color: var(--menuColor);
          }

          &:hover,
          &.active {
            &:after {
              width: 100%;
              visibility: visible;
              opacity: 1;
            }
          }
        }

        &:last-child {
          &>.nav-link {
            padding-right: 0;

            &.dropdown-toggle {
              padding-right: 15px;
            }
          }
        }
      }
    }
  }

  .app-btn {
    a {
      width: 59px;
      height: 59px;
      border: 1px solid rgba(18, 9, 45, 0.15);

      img {
        transition: var(--transition);
        max-width: 23px;
        position: relative;
      }

      &:first-child {
        margin-right: 15px;

        img {
          top: -1px;
        }
      }

      &:last-child {
        img {
          left: 3px;
        }
      }

      &:hover {
        background-color: var(--titleColor);

        &:first-child {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .navbar-area {

    &.home_one,
    &.home_three,
    &.home_four,
    &.home_five,
    &.home_six,
    &.home_seven {
      top: 15px;

      .navbar-wrapper {
        padding: 10px 18px;
      }

      &.sticky {
        .navbar-wrapper {
          padding: 12px 0 10px;
        }
      }
    }

    &.home_five {
      padding-top: 12px;

      &.sticky {
        padding-top: 0;
      }
    }

    .logo {
      img {
        max-width: 100px;
      }
    }

    .other-options {
      .app-btn {
        margin-right: 12px;

        a {
          width: 40px;
          height: 40px;

          &:first-child {
            margin-right: 12px;
          }

          img {
            max-width: 15px;
          }
        }
      }
    }

    &.home_two,
    &.home_eight {
      padding: 17px 0;
      border-bottom: 1px solid rgba(167, 97, 248, 0.16);

      &.sticky {
        padding: 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area {
    .navbar {
      .navbar-nav {
        padding-left: 35px;

        .nav-item {
          margin-right: 23px;
        }
      }
    }

    &.home_eight {
      .other-options {
        .option-item {
          .search-btn {
            margin-right: 15px;
          }

          .login-btn {
            padding: 7px 21px;

            &:first-child {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .navbar-area {
    .navbar {
      .navbar-nav {
        padding-left: 74px;
      }
    }

    &.home_eight {
      .navbar {
        .navbar-nav {
          padding-left: 63px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .navbar-area {
    .navbar {
      .navbar-nav {
        padding-left: 74px;
      }
    }

    &.home_two {
      .container {
        max-width: 1578px;
      }
    }

    &.home_four {
      .container {
        max-width: 1400px;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .navbar-area {
    &.home_eight {
      .container {
        max-width: 1630px;
      }
    }
  }
}
</style>