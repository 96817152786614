<template>
  <div id="inicio" class="home_one-hero-section bg-f ptb-100 mb-100 mn-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="hero-content">
            <h1 data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              Atendimento médico, onde quer que você esteja.
            </h1>
            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              Experimente um atendimento médico de excelência, disponível na palma da sua mão. Com a telemedicina, seu
              médico está apenas a um clique de distância, pronto para te atender em minutos.
            </p>
            <div class="hero-btn" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <router-link to="/login" class="btn style-one">
                Saiba mais
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="hero-img-wrap" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            <img src="../../assets/img/doctor-here/hero-phone.png" alt="Image"
              class="hero-img position-relative index-1 d-block mx-auto bounce" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>

<style lang="scss" scoped>
.home_one-hero-section {
  background-image: url(../../assets/img/doctor-here/hero-bg.jpg);
  padding: 220px 0 100px;

  .hero-img-wrap {
    position: relative;
    right: 50px;

    .hero-shape-one,
    .hero-shape-two {
      position: absolute;
    }

    .hero-shape-one {
      top: 5%;
      left: 14%;
    }

    .hero-shape-two {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      z-index: 0;
    }
  }

  .hero-content {
    h1 {
      font-size: 70px;
      line-height: 84px;
      margin: 0 0 25px;
      letter-spacing: -1.6px;
    }

    p {
      margin: 0 0 45px;
      color: #525066;
      padding-right: 30px;
    }

    .hero-btn {
      a {
        &:first-child {
          margin-right: 20px;
        }

        &.link {
          span {
            text-decoration: underline;
          }

          img {
            position: relative;
            top: -3px;
            margin-left: 10px;
          }

          &:hover {
            span {
              color: var(--primaryColor);
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-hero-section {
    padding: 140px 0 65px;

    .hero-content {
      margin-bottom: 30px;

      h1 {
        font-size: 35px;
        line-height: 47px;
        margin-bottom: 15px;
      }

      p {
        padding-right: 0;
        margin: 0 0 25px;
      }

      .hero-btn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        a {

          &.link,
          &.btn {
            margin-bottom: 20px;
          }
        }
      }
    }

    .hero-img-wrap {
      right: auto;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home_one-hero-section {
    padding: 140px 0 100px;

    .hero-content {
      margin-bottom: 30px;

      h1 {
        font-size: 50px;
      }
    }

    .hero-img-wrap {
      .hero-img {
        right: 5px;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home_one-hero-section {
    .hero-content {
      h1 {
        font-size: 64px;
        line-height: 75px;
      }

      p {
        padding-right: 50px;
      }

      .hero-btn {
        .link {
          display: block;
          margin-top: 20px;
        }
      }
    }

    .hero-img-wrap {
      .hero-img {
        right: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home_one-hero-section {
    .hero-img-wrap {
      .hero-shape-two {
        right: -32px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_one-hero-section {
    .hero-content {
      position: relative;
      top: -10px;

      h1 {
        font-size: 80px;
        line-height: 96px;
      }

      p {
        padding-right: 80px;
      }
    }

    .hero-img-wrap {
      position: relative;
      left: 30px;

      .hero-img {
        right: 88px;
      }

      .hero-shape-two {
        right: 14px;
      }
    }
  }
}
</style>