<template>
  <div class="container home_one-simple-wrapper pt-100">
    <div class="row align-items-end">
      <div class="col-lg-6">
        <div class="simple-content">
          <h2 class="section-title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
            Evite longas esperas em filas de hospitais e tenha acesso à saúde ao alcance de suas mãos!
          </h2>
          <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">

            Realize suas consultas médicas e obtenha receitas, atestados e solicitações de exames, tudo isso sem
            precisar sair de casa. Afinal, sua saúde merece a melhor assistência e você merece toda a comodidade
            possível!
          </p>
          <router-link to="#como-baixar" class="btn style-one" data-aos="fade-up" data-aos-duration="1000"
            data-aos-delay="300">
            Baixar aplicativo
          </router-link>
          <div class="feature-content" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400">
            <div class="feature-item">
              <h5>
                <img src="../../assets/img/icons/check-2.svg" alt="Image" />
                Pronto atendimento 24h
              </h5>
              <p>
                Consultas com o Clínico Geral ou Pediatra, disponíveis 24h por dia e 7 dias na semana! O paciente
                recebe: receitas, atestados e
                encaminhamentos para outras especialidades normalmente, como em um atendimento presencial.
              </p>
            </div>
            <div class="feature-item">
              <h5>
                <img src="../../assets/img/icons/check-2.svg" alt="Image" />
                Especialidades online
              </h5>
              <p>
                São mais de 20 especialidades médicas disponíveis para agendamento online! Garantimos o cuidado total
                da sua saúde.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="simple-img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
          <img src="../../assets/img/doctor-here/simple-img.png" alt="Image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YourGatewayToReliable",
};
</script>

<style lang="scss" scoped>
.home_one-simple-wrapper {
  .simple-content {
    p {
      margin: 30px 0 40px;
    }

    .feature-content {
      border-radius: 10px;
      background-color: #f3feff;
      padding: 50px 50px 41px;
      margin-top: 70px;

      .feature-item {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        h5 {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 14px;

          img {
            margin-right: 12px;
            position: relative;
            top: -2px;
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-simple-wrapper {
    .simple-content {
      p {
        margin: 20px 0 30px;
      }

      .feature-content {
        padding: 35px 20px 30px;
        margin-top: 30px;

        .feature-item {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .home_one-simple-wrapper {
    .simple-content {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_one-simple-wrapper {
    .simple-content {
      width: calc(100% + 8px);

      &>p {
        padding-right: 50px;
      }
    }

    .simple-img {
      position: relative;
      left: 7px;
    }
  }
}
</style>