<template>
  <div class="container ptb-100">
    <div class="row align-items-center mb-40" data-cue="slideInUp">
      <div class="col-lg-6">
        <h2 class="section-title">Perguntas frequentes</h2>
      </div>
      <div class="col-lg-6 text-lg-end mt-md-20 mt-sm-20">
        <router-link to="/contact-us" class="btn style-two">
          Fale conosco
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-10 offset-xl-1">
        <div class="home_one-accordion accordion" id="accordionExample_two">
          <div class="accordion-item transition" data-bs-toggle="collapse" data-bs-target="#collapseEight"
            aria-expanded="true" aria-controls="collapseEight" role="button" data-cue="slideInUp">
            <div class="accordion-header" id="headingEight">
              <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                Médico Aqui é um plano de saúde? <span class="accord-arrow">
                  <i class="ri-arrow-down-s-line plus"></i>
                  <i class="ri-arrow-up-s-line minus"></i>
                </span>
              </div>
            </div>
            <div id="collapseEight" class="accordion-collapse collapse show" aria-labelledby="headingEight"
              data-bs-parent="#accordionExample_two">
              <div class="accordion-body">
                <p>
                  Não, é um serviço de consultas online, sem carência.
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item collapsed transition" data-bs-toggle="collapse" data-bs-target="#collapseNine"
            aria-expanded="false" aria-controls="collapseNine" role="button" data-cue="slideInUp">
            <div class="accordion-header" id="headingNine">
              <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                Há limites para consulta?
                <span class="accord-arrow">
                  <i class="ri-arrow-down-s-line plus"></i>
                  <i class="ri-arrow-up-s-line minus"></i>
                </span>
              </div>
            </div>
            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine"
              data-bs-parent="#accordionExample_two">
              <div class="accordion-body">
                <p>
                  Não! Você pode se consultar quando achar necessário. E se optar pelo plano familiar, sua família
                  também.


                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item collapsed transition" data-bs-toggle="collapse" data-bs-target="#collapseTen"
            aria-expanded="false" aria-controls="collapseTen" role="button" data-cue="slideInUp">
            <div class="accordion-header" id="headingTen">
              <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                Como contratar?
                <span class="accord-arrow">
                  <i class="ri-arrow-down-s-line plus"></i>
                  <i class="ri-arrow-up-s-line minus"></i>
                </span>
              </div>
            </div>
            <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen"
              data-bs-parent="#accordionExample_two">
              <div class="accordion-body">
                <p>

                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item collapsed transition" data-bs-toggle="collapse" data-bs-target="#collapseEleven"
            aria-expanded="false" aria-controls="collapseEleven" role="button" data-cue="slideInUp">
            <div class="accordion-header" id="headingEleven">
              <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                Quais as formas de pagamento?
                <span class="accord-arrow">
                  <i class="ri-arrow-down-s-line plus"></i>
                  <i class="ri-arrow-up-s-line minus"></i>
                </span>
              </div>
            </div>
            <div id="collapseEleven" class="accordion-collapse collapse" aria-labelledby="headingEleven"
              data-bs-parent="#accordionExample_two">
              <div class="accordion-body">
                <p>
                  Cartão de Crédito, Cartão de Débito, PIX


                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item collapsed transition" data-bs-toggle="collapse" data-bs-target="#collapseTwelve"
            aria-expanded="false" aria-controls="collapseTwelve" role="button" data-cue="slideInUp">
            <div class="accordion-header" id="headingTweleve">
              <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                Qual a diferença entre os planos bronze, prata e ouro?
                <span class="accord-arrow">
                  <i class="ri-arrow-down-s-line plus"></i>
                  <i class="ri-arrow-up-s-line minus"></i>
                </span>
              </div>
            </div>
            <div id="collapseTwelve" class="accordion-collapse collapse" aria-labelledby="headingTweleve"
              data-bs-parent="#accordionExample_two">
              <div class="accordion-body">
                <p>
                  No Plano Individual, você tem direito a atendimentos ilimitados por telemedicina.
                  No Plano Família, você pode estender todos esses benefícios para um total de até 4 pessoas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrequentlyAskedQuestions",
};
</script>

<style lang="scss" scoped>
.home_one-accordion {
  .accordion-item {
    margin: 0 0 32px;
    border: none;
    border-radius: 10px;

    &:not(:first-of-type) {
      border-top: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      margin-bottom: 0;
      position: relative;
      z-index: 3;
      border-radius: 0;

      .accordion-button {
        border-radius: 0;
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        display: block;
        box-shadow: none;

        &:after {
          display: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        .accord-arrow {
          display: inline-block;
          width: 30px;
          height: 100%;
          position: absolute;
          top: 0;
          right: -5px;
          font-size: 22px;
          font-weight: 500;
          padding: 0;
          text-align: left;

          i {
            color: var(--titleColor);
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }

    .accordion-body {
      padding: 15px 0 0;

      p {
        color: var(--offwhiteColor);
        margin: 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.collapsed {
      background-color: transparent;
      border: 1px solid rgba(167, 97, 248, 0.21);
      color: var(--secondaryColor) !important;
      padding: 21px 35px 19px;

      .accordion-button {
        color: var(--secondaryColor);
      }

      .accord-arrow {
        i {
          color: var(--titleColor);

          &.minus {
            visibility: hidden;
            opacity: 0;
          }

          &.plus {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &:not(.collapsed) {
      background-color: var(--optionalColor);
      padding: 32px 35px 32px;

      .accordion-button {
        color: var(--whiteColor) !important;
      }

      .accord-arrow {
        i {
          color: var(--whiteColor) !important;

          &.plus {
            visibility: hidden;
            opacity: 0;
          }

          &.minus {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-size: 18px;

          span {
            top: 3px;

            i {
              font-size: 19px;
            }
          }
        }
      }

      &.collapsed {
        padding: 20px 22px 20px;
      }

      &:not(.collapsed) {
        padding: 28px 22px 28px;
      }
    }
  }
}
</style>