import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_ResponsiveNavbar = _resolveComponent("ResponsiveNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_WhyChoiceForYou = _resolveComponent("WhyChoiceForYou")!
  const _component_BrandLogo = _resolveComponent("BrandLogo")!
  const _component_FeaturesContent = _resolveComponent("FeaturesContent")!
  const _component_YourGatewayToReliable = _resolveComponent("YourGatewayToReliable")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_PricingPlan = _resolveComponent("PricingPlan")!
  const _component_FrequentlyAskedQuestions = _resolveComponent("FrequentlyAskedQuestions")!
  const _component_OurLatestBlog = _resolveComponent("OurLatestBlog")!
  const _component_DownloadTheApp = _resolveComponent("DownloadTheApp")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainNavbar, { class: "home_one" }),
    _createVNode(_component_ResponsiveNavbar, { class: "home_one" }),
    _createVNode(_component_MainBanner),
    _createVNode(_component_WhyChoiceForYou),
    _createVNode(_component_BrandLogo, { class: "pb-100" }),
    _createVNode(_component_FeaturesContent),
    _createVNode(_component_YourGatewayToReliable),
    _createVNode(_component_HowItWorks),
    _createVNode(_component_PricingPlan),
    _createVNode(_component_FrequentlyAskedQuestions),
    _createVNode(_component_OurLatestBlog),
    _createVNode(_component_DownloadTheApp),
    _createVNode(_component_MainFooter)
  ], 64))
}