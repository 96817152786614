<template>
  <MainNavbar class="home_one" />
  <ResponsiveNavbar class="home_one" />
  <MainBanner />
  <WhyChoiceForYou />
  <BrandLogo class="pb-100" />
  <FeaturesContent />
  <YourGatewayToReliable />
  <HowItWorks />
  <PricingPlan />
  <FrequentlyAskedQuestions />
  <OurLatestBlog />
  <DownloadTheApp />
  <MainFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BrandLogo from "../components/Common/BrandLogo.vue";
import DownloadTheApp from "../components/Common/DownloadTheApp.vue";
import OurLatestBlog from "../components/Common/OurLatestBlog.vue";
import FeaturesContent from "../components/HomeOne/FeaturesContent.vue";
import FrequentlyAskedQuestions from "../components/HomeOne/FrequentlyAskedQuestions.vue";
import HowItWorks from "../components/HomeOne/HowItWorks.vue";
import MainBanner from "../components/HomeOne/MainBanner.vue";
import PricingPlan from "../components/HomeOne/PricingPlan.vue";
import WhyChoiceForYou from "../components/HomeOne/WhyChoiceForYou.vue";
import YourGatewayToReliable from "../components/HomeOne/YourGatewayToReliable.vue";
import MainFooter from "../components/Layouts/MainFooter.vue";
import MainNavbar from "../components/Layouts/MainNavbar.vue";
import ResponsiveNavbar from "../components/Layouts/ResponsiveNavbar.vue";

export default defineComponent({
  name: "HomeOnePage",
  components: {
    MainNavbar,
    ResponsiveNavbar,
    MainBanner,
    WhyChoiceForYou,
    BrandLogo,
    FeaturesContent,
    YourGatewayToReliable,
    HowItWorks,
    PricingPlan,
    // WhatOurCustomerSays,
    // WhatOurCustomerSaysTwo,
    FrequentlyAskedQuestions,
    OurLatestBlog,
    DownloadTheApp,
    MainFooter,
  },
});
</script>