<template>
  <footer class="footer-wrap style-one pt-100">
    <div class="container pb-100">
      <div class="row footer-widget-wrap">
        <div class="col-xl-3 col-md-6 col-lg-6 col-md-6">
          <div class="footer-widget mb-30">
            <router-link to="/">
              <img src="../../assets/img/doctor-here/logo-white.png" alt="Image" />
            </router-link>
            <p class="comp-desc text-offwhite">
              Aonde você estiver, a um clique do seu médico, sempre.
            </p>
            <ul class="social-profile list-style d-flex align-items-center">
              <li>
                <a href="https://www.fb.com" target="_blank">
                  <img src="../../assets/img/icons/facebook.svg" alt="Image" />
                </a>
              </li>
              <li>
                <a href="https://www.pinterest.com" target="_blank">
                  <img src="../../assets/img/icons/pinterest.svg" alt="Image" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com" target="_blank">
                  <img src="../../assets/img/icons/instagram.svg" alt="Image" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com" target="_blank">
                  <img src="../../assets/img/icons/twitter.svg" alt="Image" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xl-2 offset-xl-1 col-md-6">
          <div class="footer-widget mb-30">
            <h4 class="footer-widget-title fs-22 text-white">Páginas</h4>

            <ul class="footer-menu list-style">
              <li><router-link to="#">FAQ</router-link></li>
              <li>
                <router-link to="/shop-left-sidebar">
                  Nossos serviços
                </router-link>
              </li>
              <li><router-link to="#">LGPD</router-link></li>
              <li>
                <router-link to="#">Política de privacidade</router-link>
              </li>
              <li>
                <router-link to="#">
                  Termos & condições
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 ps-xxl-3 ps-xl-6">
          <div class="footer-widget mb-30">
            <h4 class="footer-widget-title fs-22 text-white">Acesso rápido</h4>

            <ul class="footer-menu list-style">
              <li><router-link to="#">Sobre-nós</router-link></li>
              <li><router-link to="#">Contato</router-link></li>
              <li>
                <router-link to="#r">Últimas notícias</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 ps-xxl-6">
          <div class="footer-widget mb-30">
            <h4 class="footer-widget-title fs-22 text-white">Contato</h4>

            <ul class="contact-info list-style">
              <li class="position-relative">
                <img src="../../assets/img/icons/phone.svg" alt="Image" class="position-absolute start-0" />
                <a href="tel:5565996679293">+55 65 99667 9293</a>
              </li>
              <li class="position-relative">
                <img src="../../assets/img/icons/comment.svg" alt="Image" class="position-absolute start-0" />
                <a href="mailto:contato@medicoaqui.com.br">contato@medicoaqui.com.br</a>
              </li>
              <li class="position-relative">
                <img src="../../assets/img/icons/comment.svg" alt="Image" class="position-absolute start-0" />
                <a href="mailto:suporte@medicoaqui.com.br">suporte@medicoaqui.com.br</a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>

    <p class="copyright-text mb-0 text-center">
      <i class="ri-copyright-line"></i>
      <span>Médico Aqui, 2024. Todos os direitos reservados.</span>
    </p>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  background-color: #142235;

  .footer-widget {
    .comp-desc {
      margin: 28px 0 38px;
    }

    .footer-widget-title {
      margin-bottom: 26px;
    }

    .social-profile {
      li {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        a {
          img {
            filter: brightness(0) invert(1);
            transition: var(--transition);
          }

          &:hover {
            img {
              filter: brightness(1) invert(0);
            }
          }
        }
      }
    }

    .footer-menu {
      li {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: var(--offwhiteColor);

          &:hover {
            color: var(--primaryColor);
          }
        }
      }
    }

    .contact-info {
      li {
        position: relative;
        padding-left: 22px;
        margin-bottom: 15px;
        color: var(--offwhiteColor);

        &:last-child {
          margin-bottom: 0;
        }

        img {
          top: 6.5px;
        }

        a {
          color: var(--offwhiteColor);

          &:hover {
            color: var(--primaryColor);
          }
        }
      }
    }

    .app-widget {
      background: rgba(255, 255, 255, 0.08);
      padding: 30px 35px 35px;

      .app-btn {
        a {
          display: inline-block;
          padding: 11px 18.5px 10px;

          &:first-child {
            margin-right: 20px;
          }

          &:hover {
            background-color: #f3795d;
            border-color: transparent;
          }

          &.google-playstore {
            border: 1px solid var(--whiteColor);

            &:hover {
              border-color: transparent;
              background-color: var(--bruntColor);

              img {
                filter: brightness(0) invert(1);
              }
            }
          }

          &.apple-playstore {
            border-radius: 6px;
            padding: 12px 18.5px 11px;
            background-color: var(--bruntColor);

            &:hover {
              background-color: var(--titleColor);
            }
          }
        }
      }
    }
  }
}

.copyright-text {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 17px 0;
  color: rgba(255, 255, 255, 0.65);

  a,
  span {
    color: var(--whiteColor);
  }

  a {
    &:hover {
      color: var(--primaryColor);
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-wrap {
    .footer-widget {
      .footer-widget-title {
        margin-bottom: 20px;
      }

      .comp-desc {
        margin: 22px 0 28px;
      }

      .contact-info {
        li {
          font-size: 14px;
          margin-bottom: 9px;

          a {
            font-size: 14px;
          }
        }
      }

      .footer-menu {
        li {
          margin-bottom: 9px;

          a {
            font-size: 14px;
          }
        }
      }

      .app-widget {
        padding: 25px 22px 15px;

        h5 {
          line-height: 30px;
        }

        .app-btn {
          a {
            margin-bottom: 15px;
            padding: 11px 15.5px 10px;

            img {
              max-width: 100px;
            }

            &:first-child {
              margin-right: 10px;
            }

            &.apple-playstore {
              padding: 12px 15.5px 11px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .footer-wrap {
    .footer-widget {
      .app-widget {
        padding: 30px 25px 35px;

        .app-btn {
          a {
            padding: 11px 15.5px 10px;

            img {
              max-width: 115px;
            }

            &:first-child {
              margin-right: 12px;
            }

            &.apple-playstore {
              padding: 12px 15.5px 11px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .footer-wrap {
    &.style-one {
      .footer-widget-wrap {
        &>div {
          &:nth-child(2) {
            .footer-widget {
              position: relative;
              left: 54px;
            }
          }

          &:nth-child(3) {
            .footer-widget {
              position: relative;
              left: 125px;
            }
          }

          &:nth-child(4) {
            .footer-widget {
              position: relative;
              left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>