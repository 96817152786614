<template>
  <div id="about-area" class="home_one-about-wrapper pb-100 mn-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-img-wrap position-relative">
            <img src="../../assets/img/home-one/about/circle.webp" alt="Image" class="about-shape position-absolute"
              data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" />
            <img src="../../assets/img/home-one/about/about-img.webp" alt="Image" class="about-img" data-aos="fade-up"
              data-aos-duration="1000" data-aos-delay="100" />
            <div class="about-promo-wrapper" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              <div class="row gx-xl-5">
                <div class="col-md-6">
                  <div class="featured-card bg-white">
                    <span class="d-flex flex-column justify-content-center align-items-center rounded-circle">
                      <img src="../../assets/img/icons/language.svg" alt="Image" />
                    </span>
                    <h6 class="fs-20 mb-10">Multi-idiomas</h6>
                    <p class="mb-0">
                      Garantimos uma experiência acessível e inclusiva para todos.
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="about-promo-card style-one bg-white">
                    <h6>
                      <img src="../../assets/img/icons/check-2.svg" alt="Image" />
                      24h/7 online
                    </h6>
                    <p class="mb-0">Atendimentos 24h por dia</p>
                  </div>
                  <div class="about-promo-card style-two bg-white">
                    <h6>
                      <img src="../../assets/img/icons/check-2.svg" alt="Image" />
                      Saúde inteligente
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 offset-xl-1 col-lg-6">
          <div class="about-content">
            <h2 class="section-title" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              Uma solução completa em saúde digital.
            </h2>
            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
              Somos uma empresa de telemedicina totalmente online, dedicada a proporcionar acesso fácil e conveniente a
              uma variedade de serviços de saúde e tecnologias inovadoras, tudo focado em promover seu bem-estar e
              saúde.
            </p>
            <div class="home_one-accordion accordion" id="accordionExample" data-aos="fade-up" data-aos-duration="1000"
              data-aos-delay="300">
              <div class="accordion-item transition" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne" role="button">
                <div class="accordion-header" id="headingOne">
                  <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                    <span class="accord-counter">01.</span>Acompanhamento vital
                    <span class="accord-arrow">
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                  </div>
                </div>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Leituras em tempo real do seus batimentos cardíacos, pressão arterial, níveis
                      de oxigenação e temperatura corporal. Mantenha-se informado sobre sua saúde a qualquer momento e
                      em qualquer lugar. Acompanhamento vital pelo aplicativo e mantenha a janela para uma vida mais
                      saudável e consciente.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item collapsed transition" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo" role="button">
                <div class="accordion-header" id="headingTwo">
                  <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                    <span class="accord-counter">02.</span>Exames auxiliares rPPG
                    <span class="accord-arrow">
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                  </div>
                </div>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      O monitoramento de saúde sem contato é um método não invasivo de
                      medição e rastreamento de parâmetros vitais de saúde sem contato físico
                      com o indivíduo monitorado. Esta abordagem depende de tecnologias
                      avançadas, como fotopletismografia remota (rPPG), visão computacional e
                      algoritmos de aprendizado de máquina para coletar dados essenciais de
                      saúde à distância
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item collapsed transition" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                aria-expanded="false" aria-controls="collapseThree" role="button">
                <div class="accordion-header" id="headingThree">
                  <div class="accordion-button f-secondary bg-transparent border-0 p-0">
                    <span class="accord-counter">03.</span>Saúde inteligênte 🧠
                    <span class="accord-arrow">
                      <i class="ri-arrow-down-s-line plus"></i>
                      <i class="ri-arrow-up-s-line minus"></i>
                    </span>
                  </div>
                </div>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>
                      Tecnologia para Cuidar de Você. Combinamos inovação tecnológica e cuidados com a saúde para
                      oferecer uma abordagem totalmente nova ao bem-estar. Nossa plataforma utiliza inteligência
                      artificial e dispositivos conectados para fornecer insights personalizados sobre sua saúde em
                      tempo real. Desde o monitoramento contínuo de sinais vitais até a análise preditiva de tendências
                      de saúde, estamos aqui para ajudá-lo a tomar decisões informadas e proativas sobre seu estilo de
                      vida. Descubra o poder da saúde inteligente hoje mesmo."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChoiceForYou",
};
</script>

<style lang="scss" scoped>
.home_one-about-wrapper {
  .about-img-wrap {
    padding-left: 110px;

    .about-shape {
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    .about-img {
      margin-left: auto;
      border-radius: 10px;
    }

    .about-promo-wrapper {
      position: absolute;
      right: 0;
      bottom: 43px;

      .featured-card {
        padding: 45px 25px 18px 24px;
        border-radius: 10px;
        background: #fff;
        position: relative;
        box-shadow: 0px 0px 41px 30px rgba(15, 9, 72, 0.03);

        span {
          width: 59px;
          height: 59px;
          position: absolute;
          top: -28px;
          left: 25px;
          background-color: #f6eeff;
          z-index: 1;

          img {
            max-width: 28px;
          }
        }
      }
    }

    .about-promo-card {
      margin-bottom: 15px;
      border-radius: 10px;
      box-shadow: 0px 0px 41px 30px rgba(15, 9, 72, 0.03);

      h6 {
        font-size: 20px;
        position: relative;
        padding-left: 30px;

        img {
          margin-right: 9px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.style-one {
        padding: 24px 20px 22px 26px;

        h6 {
          margin-bottom: 11px;
        }
      }

      &.style-two {
        padding: 26px 20px 24px 26px;

        h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  .about-content {
    p {
      margin: 30px 0;
    }

    .home_one-accordion {
      .accordion-item {
        margin: 0 0 25px;

        .accordion-header {
          .accordion-button {
            .accord-counter {
              font-size: 20px;
              color: #6b7aff;
              margin-right: 8px;
            }
          }
        }

        .accordion-body {
          padding: 12px 0 0;
        }

        &.collapsed {
          background-color: transparent;
          border: 1px solid rgba(167, 97, 248, 0.16);
          color: var(--secondaryColor) !important;
          padding: 17px 25px 17px 30px;

          .accordion-button {
            color: var(--secondaryColor);
          }
        }

        &:not(.collapsed) {
          background-color: var(--optionalColor);
          padding: 21px 25px 21px 30px;

          .accordion-button {
            color: var(--secondaryColor);

            .accord-counter {
              color: var(--whiteColor);
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.home_one-accordion {
  .accordion-item {
    margin: 0 0 32px;
    border: none;
    border-radius: 10px;

    &:not(:first-of-type) {
      border-top: none;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .accordion-header {
      margin-bottom: 0;
      position: relative;
      z-index: 3;
      border-radius: 0;

      .accordion-button {
        border-radius: 0;
        margin: 0;
        font-size: 20px;
        font-weight: 500;
        display: block;
        box-shadow: none;

        &:after {
          display: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        .accord-arrow {
          display: inline-block;
          width: 30px;
          height: 100%;
          position: absolute;
          top: 0;
          right: -5px;
          font-size: 22px;
          font-weight: 500;
          padding: 0;
          text-align: left;

          i {
            color: var(--titleColor);
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }

    .accordion-body {
      padding: 15px 0 0;

      p {
        color: var(--offwhiteColor);
        margin: 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.collapsed {
      background-color: transparent;
      border: 1px solid rgba(167, 97, 248, 0.21);
      color: var(--secondaryColor) !important;
      padding: 21px 35px 19px;

      .accordion-button {
        color: var(--secondaryColor);
      }

      .accord-arrow {
        i {
          color: var(--titleColor);

          &.minus {
            visibility: hidden;
            opacity: 0;
          }

          &.plus {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &:not(.collapsed) {
      background-color: var(--optionalColor);
      padding: 32px 35px 32px;

      .accordion-button {
        color: var(--whiteColor) !important;
      }

      .accord-arrow {
        i {
          color: var(--whiteColor) !important;

          &.plus {
            visibility: hidden;
            opacity: 0;
          }

          &.minus {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home_one-about-wrapper {
    .about-img-wrap {
      padding-left: 0;

      .about-shape {
        top: 50%;
      }

      .about-promo-wrapper {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: 50px;

        .featured-card {
          margin-bottom: 18px;
        }
      }
    }

    .about-content {
      .home_one-accordion {
        .accordion-item {
          .accordion-header {
            .accordion-button {
              font-size: 18px;

              span {
                top: 3px;

                i {
                  font-size: 19px;
                }
              }
            }
          }

          &.collapsed {
            padding: 20px 22px 20px;
          }

          &:not(.collapsed) {
            padding: 28px 22px 28px;
          }
        }
      }
    }
  }

  .home_one-accordion {
    .accordion-item {
      .accordion-header {
        .accordion-button {
          font-size: 18px;

          span {
            top: 3px;

            i {
              font-size: 19px;
            }
          }
        }
      }

      &.collapsed {
        padding: 20px 22px 20px;
      }

      &:not(.collapsed) {
        padding: 28px 22px 28px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .home_one-about-wrapper {
    .about-img-wrap {
      margin-bottom: 25px;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .home_one-about-wrapper {
    .about-img-wrap {
      .about-promo-wrapper {
        right: -20px;

        .featured-card {
          top: -5px;
          position: relative;
        }

        .featured-card,
        .about-promo-card {
          min-width: 323px;
        }

        .about-promo-card {
          margin-left: 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .home_one-about-wrapper {
    .about-img-wrap {
      .about-img {
        position: relative;
        left: 5px;
      }

      .about-promo-wrapper {
        right: -30px;

        .featured-card {
          top: -5px;
          position: relative;
        }

        .featured-card,
        .about-promo-card {
          min-width: 323px;
        }

        .about-promo-card {
          margin-left: 25px;
        }
      }
    }

    .about-content {
      width: calc(100% - 10px);
      margin-left: auto;
    }
  }
}
</style>