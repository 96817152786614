<template>
  <div id="blog-area" class="container pb-70 mn-section">
    <div class="row align-items-center mb-50" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
      <div class="col-lg-6 col-md-7 pe-xxl-6">
        <h2 class="section-title pe-xxl-5">
          Últimas atualizações
        </h2>
      </div>
      <div class="col-lg-6 col-md-5 text-md-end">
        <router-link to="/blog-left-sidebar" class="btn style-one">
          Visualizar todos
        </router-link>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-xl-6">
        <div class="blog-card style-one mb-30" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="200">
          <div class="blog-img round-10 overflow-hidden">
            <router-link to="#">
              <img src="../../assets/img/home-two/blog/blog-4.webp" alt="Image" class="round-10 transition" />
            </router-link>
          </div>
          <div class="blog-info">
            <ul class="blog-metainfo list-style">
              <li>
                <router-link to="/posts-by-author">
                  <img src="../../assets/img/icons/user.svg" alt="Image" />
                  Redator
                </router-link>
              </li>
              <li>
                <img src="../../assets/img/icons/calendar.svg" alt="Image" />
                18 de Março, 2024
              </li>
            </ul>
            <h3 class="fs-26 lh-36">
              <router-link to="#">
                INSS vai permitir uso da telemedicina para concessão de benefícios previdenciários
              </router-link>
            </h3>
            <router-link to="#" class="link style-one">
              Ler mais
              <img src="../../assets/img/icons/long-arrow.svg" alt="Image" />
            </router-link>
          </div>
        </div>
      </div>

      <div class="col-xl-6 pe-xl-0">
        <div class="blog-card style-two d-md-flex align-items-md-center mb-30" data-aos="fade-up"
          data-aos-duration="1000" data-aos-delay="300">
          <div class="blog-img round-10 overflow-hidden">
            <router-link to="#">
              <img src="../../assets/img/home-two/blog/blog-3.webp" alt="Image" class="round-10 transition" />
            </router-link>
          </div>
          <div class="blog-info">
            <ul class="blog-metainfo list-style">
              <li>
                <img src="../../assets/img/icons/calendar.svg" alt="Image" />
                18 de Março, 2024
              </li>
            </ul>
            <h3 class="fs-20 lh-36">
              <router-link to="#">
                MPS inicia perícias médicas com uso de telemedicina
              </router-link>
            </h3>
            <p>
              Meta é realizar 50 mil perícias por telemedicina mensalmente.
            </p>
            <router-link to="#" class="link style-one">
              Leia mais
              <img src="../../assets/img/icons/long-arrow.svg" alt="Image" />
            </router-link>
          </div>
        </div>
        <div class="blog-card style-two d-md-flex align-items-md-center mb-30" data-aos="fade-up"
          data-aos-duration="1000" data-aos-delay="400">
          <div class="blog-img round-10 overflow-hidden">
            <router-link to="#">
              <img src="../../assets/img/home-two/blog/blog-2.webp" alt="Image" class="round-10 transition" />
            </router-link>
          </div>
          <div class="blog-info">
            <ul class="blog-metainfo list-style">
              <li>
                <img src="../../assets/img/icons/calendar.svg" alt="Image" />
                18 de Março, 2024
              </li>
            </ul>
            <h3 class="fs-20 lh-36">
              <router-link to="#">
                Além da telemedicina: conheça outras 3 inovações na saúde
              </router-link>
            </h3>
            <p>
              Além da telemedicina: conheça outras 3 inovações na saúde · Artigo · Inteligência artificial, coleta e
              análise de dados e interoperabilidade de...
            </p>
            <router-link to="#" class="link style-one">
              Leia mais
              <img src="../../assets/img/icons/long-arrow.svg" alt="Image" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurLatestBlog",
};
</script>

<style lang="scss" scoped>
.blog-card {
  margin-bottom: 30px;

  .blog-metainfo {
    margin: 30px 0 0;

    li {
      display: inline-block;
      margin-right: 21px;
      position: relative;
      padding-left: 21px;

      img {
        position: absolute;
        top: 4px;
        left: 0;
      }

      a {
        &:hover {
          color: var(--primaryColor);
        }
      }
    }
  }

  h3 {
    margin: 12px 0 23px;
  }

  &.style-two {
    .blog-img {
      width: 312px;
    }

    .blog-info {
      width: calc(100% - 336px);
      margin-left: 24px;

      .blog-metainfo {
        margin-top: 0;
      }

      h3 {
        margin: 8px 0 15px;
        line-height: 28px;
      }

      p {
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 15px;
  }

  .blog-card {
    .blog-metainfo {
      margin: 20px 0 0;

      li {
        margin-right: 14px;
        font-size: 14px;

        img {
          top: 4px;
        }
      }
    }

    h3 {
      margin: 12px 0 15px;
    }

    &.style-two {
      .blog-img {
        width: 100%;
      }

      .blog-info {
        width: 100%;
        margin: 24px 0 0;

        p {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1400px) {
  .blog-card {
    &.style-one {
      width: calc(100% + 8px);
      color: #45bcc4;
    }

    &.style-two {
      width: calc(100% - 8px);
      margin-left: auto;
    }
  }
}
</style>